<template>
    <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-5 relative">
            <v-app-bar
                :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
                :dark="$vuetify.theme.dark"
                class="pt-1"
                fixed
                flat
            >
                <div class="d-flex justify-space-between align-center w-full">
                    <span></span>
                    <slot name="userDrawerCloseButton"></slot>
                </div>
            </v-app-bar>
        </div>
    </vue-perfect-scrollbar>
</template>

<script>
export default {
    name: 'UserDrawer',
    props: {},
    data() {
        return {
            items: [
                {
                    letter: 'A',
                    title: 'Angular',
                    subTitle: 'Frontend framework',
                    progressbarText: '90',
                    progressvalue: 90,
                    progressColor: 'danger'
                },
                {
                    letter: 'V',
                    title: 'Vue Js',
                    subTitle: 'Frontend framework',
                    progressbarText: '30',
                    progressvalue: 30,
                    progressColor: 'green'
                },
                {
                    letter: 'R',
                    title: 'React',
                    subTitle: 'Frontend framework',
                    progressbarText: '50',
                    progressvalue: 50,
                    progressColor: 'danger'
                },
                {
                    letter: 'W',
                    title: 'Wordpress Website',
                    subTitle: 'CMS',
                    progressbarText: '30',
                    progressvalue: 30,
                    progressColor: 'dark'
                }
            ]
        }
    },
    computed: {},
    methods: {}
}
</script>

<style scoped></style>
